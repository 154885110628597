/**
 * Internationalization - Laita KAIKKI lokalisaatioihin liittyvä tänne.
 */
import areIntlLocalesSupported from 'intl-locales-supported';
import _get from 'lodash/get';
import _toString from 'lodash/toString';

/**
 * Moment
 */
import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/sv';

import { setYupLocale } from 'localization/Yup/setYupLocale';

// Lokalisaatiomapperi (http://azuliadesigns.com/list-net-culture-country-codes/)
const localeData = {
    'fi': {
        languageCulture: 'fi-FI',
        currency: 'EUR',
    },
    'sv-FI': {
        languageCulture: 'sv-FI',
        currency: 'EUR',
    },
};

const fallbackLocaleData = localeData['fi'];

// Vaaditut tuettavat kielet
export const localesSupported = Object.keys(localeData);

const localization = {

    initialize: (locale) => {
        moment.locale(locale);

        window._locale = locale;

        // Löytyykö selaimesta natiivituki Intl:lle
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
        if (global.Intl) {
            // Onko natiivilla Intl:llä ne lokalisaatiot joita me tarvitaan.
            if (!areIntlLocalesSupported(localesSupported)) {
                // Intl kyllä löytyy mutta sillä ei ole vaadittavia lokalisointeja
                // joten patchataan constructorit polyfilleillä.
                const IntlPolyfill = require('intl');
                require('intl/locale-data/jsonp/fi');
                require('intl/locale-data/jsonp/sv-FI');
                global.Intl = IntlPolyfill;
            }
        } else {
            // Ei Intl:ää ollenkaan joten käytetään polyfilliä suoraan.
            global.Intl = require('intl');
            require('intl/locale-data/jsonp/fi');
            require('intl/locale-data/jsonp/sv-FI');
        }

        // Haetaan lokalisaation mukaiset alkuasetukset lopuille lokalisoinnille
        const currentLocaleData = _get(localeData, locale.replace('_', '-'), fallbackLocaleData);
        const languageCulture = _get(currentLocaleData, 'languageCulture', null);
        const currency = _get(currentLocaleData, 'currency', null);

        // Valuuttamuunnin. Oletuksena formatoi lokalisaation mukaisen valuutan.
        // omitCurrency - näytetäänkö valuuttamerkki perässä.
        window._currency = (value, omitCurrency = false) => new Intl.NumberFormat(languageCulture, {
            style: omitCurrency ? 'decimal' : 'currency',
            currency,
        }).format(value);
        // Valuuttasymboli
        window._currencySymbol = '€';

        // Intl-objekti ei sisällä omaa parseriaan joten tehdään oma...

        // Haetaan lokalisaation mukainen tuhaterotin
        const thousandSeparator = new Intl.NumberFormat(languageCulture).format(1111).replace(/1/g, '');
        // TODO: Sepä sitten on tuo tuhaterotin NO-BREAK SPACE eikä ihan normi välilyönti mitä yleensä palvelussa käytetään...
        // Välilyönti charcode = 32, no-breaking space = 160
        // const thousandSeparator = ' ';

        // ... ja desimaalierotin
        const decimalSeparator = new Intl.NumberFormat(languageCulture).format(1.1).replace(/1/g, '');

        /**
         * Lokalisaation mukaiset desimaalierottimet. Esim: pisteet pilkuiksi
         * @param value
         * @param useGrouping - käytetäänkö tuhaterottimia.
         * @returns {string}
         */
        window._numberFormat = (value, useGrouping = true) => Intl.NumberFormat(languageCulture, { useGrouping }).format(value).replace('−', '-');

        /**
         * Muuttaa lokalisoidun numeerisen arvon takaisin numeroksi.
         * @param value
         * @returns {string|number}
         */
        window._numberParser = (value) => {
            if (! value) return '';
            const strValue = typeof value !== 'string' ? _toString(value) : value;

            // Muutetaan numeroksi...
            return parseFloat(strValue
                // White-space pois
                .replace(/\s/g, '')
                .replace('−', '-')
                // Poistaa tuhaterotinmerkit (esim. välilyönti).
                .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
                // Korvataan desimaalierotin pisteellä.
                .replace(new RegExp('\\' + decimalSeparator), '.')
            );
        };

        // Päivämääräformaatti
        // TODO: Poista browserifyn käyttö, poista requiret twigeistä tai keksi muu builditapa
        const dayMonthFormat = window._dayMonthFormat = 'DD.MM';
        const yearFormat = window._yearFormat = 'YYYY';
        window._dateFormat = `${dayMonthFormat}.${yearFormat}`; //moment.localeData().longDateFormat('L'),
        window._dateTimeFormat = `${_dateFormat} HH:mm:ss`;
        window._languageCulture = languageCulture;

        // String tai moment-objekti lokaaliin päivämäärämuotoon
        window._toLocaleDate = (date, defaultValue = '', format = _dateFormat) => {
            if (typeof date === 'string' && date.trim() !== '') {
                const momentDate = moment(date, moment.ISO_8601);
                return momentDate.isValid() ? momentDate.format(format) : defaultValue;
            } else if (moment.isMoment(date)) {
                return date.format(format);
            } else {
                return defaultValue;
            }
        };

        /**
         * Lokalisaation mukaisiin numeroihin formatointi.
         * HUOM: Käytä ainoastaan jos numeroa ei editoida, esim. palkkalaskelmat.
         * @param value
         * @param useGrouping
         * @returns {string}
         */
        window._toLocaleNumber = (value, useGrouping = true) => new Intl.NumberFormat(languageCulture, {
            useGrouping,
        }).format(value);

        // Lokalisaationmukaiset oletusvirheviestit Yup:lle
        setYupLocale();
    },
};

export default localization;
