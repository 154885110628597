import React from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import BroadcastChannel from 'broadcast-channel/dist/lib/index';
import { datadogRum } from '@datadog/browser-rum';
import localization from './localization/localization';
import CookieDisclaimer from 'shared/containers/CookieDisclaimer';
import _transMd from 'shared/utils/_transMd';
import _transObj from 'shared/utils/_transObj';
import utils from 'shared/utils/commonUtils';
import LogoutDialog, { BROADCAST_CHANNEL } from 'shared/utils/LogoutDialog';
import { PageHeader } from 'shared/containers/PageHeader';

/**
 * Alustaa eri globaalit metodit (käännökset, päivämäärä- ja valuuttaformatoijat, asettaa lokalisaatiot, ym.
 */
export default {
    init: (settings) => {
        const isDev = _.get(settings, 'isDev', false);
        const isAdmin = _.get(settings, 'isAdmin', false);

        if (isDev) {
            /**
             * Siivoaa consolen joka kerta kun tehty HMR. Ei turhaan täytä logeja.
             */
            window.addEventListener('message', (e) => {
                if (e.source === window && e.data && typeof e.data === 'string' && e.data.match(/webpackHotUpdate/)) {
                    console.clear();
                }
            });
        }

        const locale = _.get(settings, 'locale', 'fi');
        const userId = _.get(settings, 'userId', null);
        const isAuthenticated = _.get(settings, 'isAuthenticated', false);

        window.__DEV__ = isDev;

        // Käännökset
        window._trans = (id, placeholders, domain, locale) => {

            if (window._translationDomain) {
                const hasTranslation = Translator.hasMessage('fi', window._translationDomain, id);
                return Translator.trans(id, placeholders, hasTranslation ? window._translationDomain : domain, locale);
            }

            return Translator.trans(id, placeholders, domain, locale);
        };
        window._transMd = _transMd;
        window._transChoice = (id, number, placeholders, domain) => Translator.transChoice(id, number, placeholders, domain, locale);
        window._transObj = _transObj;

        // Lokalisointi
        localization.initialize(locale);

        // URLin generointi
        window._url = utils.generateUrl;

        // Keksistä kertominen
        const targetElement = document.getElementById('__cookie');
        if (targetElement !== null) {
            ReactDOM.render(
                <CookieDisclaimer isStrictDomain={! isDev} />, targetElement
            );
        }

        const element = document.getElementById('top-header');
        if (element) {
            const parent = document.createElement('div');
            // asetetaan id, jotta LogoutDialog osaa blurrata
            parent.id = 'top-header';
            ReactDOM.render(
                <PageHeader
                    baseHeading={settings.heading}
                />,
                parent
            );
            element.parentNode.replaceChild(parent, element);
        }

        if (isAuthenticated) {
            // 15 minuuttia
            window.LOGIN_TIMEOUT = 15 * 60 * 1000;
            window.logoutTimer = setTimeout(() => { ReactDOM.render(<LogoutDialog />, document.getElementById('logout-dialog')); }, window.LOGIN_TIMEOUT);
            // Viestitetään muille tabeille, että session on voimassa ja dialogi voidaan sulkea.
            const channel = new BroadcastChannel(BROADCAST_CHANNEL);
            channel.postMessage('message');
        }


        if (APP_ENV === 'prod' && isAdmin) {
            datadogRum.init({
                applicationId: '50a0a1f6-9bae-42ac-9d85-cec05a3b59ad',
                clientToken: 'pub25196ab5ef956f640037e759dcba5f75',
                // `site` refers to the Datadog site parameter of your organization
                // see https://docs.datadoghq.com/getting_started/site/
                site: 'datadoghq.eu',
                service: 'oima',
                env: APP_ENV,
                // Specify a version number to identify the deployed version of your application in Datadog
                version: BUILD_VERSION,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 0,
                trackUserInteractions: false,
                trackResources: false,
                trackLongTasks: false,
                defaultPrivacyLevel: 'mask',
                beforeSend: (event) => {
                    // ResizeObserver errori ei kiinnosta
                    if (event.type === 'error' && event.error.message.includes('ResizeObserver loop')) {
                        return false;
                    }
                },
            });

            if (userId !== null) {
                datadogRum.setUser({
                    id: userId,
                    organizationName: settings.organizationName ?? 'Tuntematon',
                    userRole: settings.userRole ?? 3,
                });

            }
        }
    },

    /**
     * Liitä komponentti DOMiin annetuilla propseilla (valinnainen).
     * Nappaa virheet redboxiin ja näyttää selaimessa.
     * @param Component
     * @param props - valinnaiset propsit
     * @param id - sen elementin id jonka sisään sisältö renderöidään
     */
    render: (Component, props = _.get(window, 'reactProps', {}), id = 'root') => {
        const element = document.getElementById(id);

        if (element) {
            ReactDOM.render(
                <Component {...props} />,
                element
            );
        }
    },
};
