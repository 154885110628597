import * as Yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

/**
 * HOX tämä tiedosto ei tykkää jos importtaa jotain mikä käyttää Translaattoria jest-testien kanssa,
 * ei ehdi tehdä näitä globaaleita muuttujia
 *
 * Asettaa Yup:lle oletusvirheviestit jottei tarvi uudestaan ja uudestaan
 * käsin laittaa näitä.
 */
export const setYupLocale = () => {
    Yup.setLocale({
        mixed: {
            // Jos yritetään castata ja epäonnistutaan annetaan vähän järkevämpi ilmoitus käyttäjälle
            notType: function notType(_ref) {
                switch (_ref.type) {
                    case 'number':
                        return _trans('Anna numeerinen arvo.', {}, 'common');
                    case 'date':
                        return _trans('Anna oikeanmuotoinen päivämäärä.', {}, 'common');
                    /*case 'string':
                        return 'Lorem ipsum';*/
                    default:
                        return _trans('Arvo on väärää tyyppiä.', {}, 'common');
                }
            },
            required: _trans('Kenttä on pakollinen.', {}, 'common'),
        },
        string: {
            email: _trans('Anna oikeanmuotoinen sähköpostiosoite.', {}, 'common'),
            url: _trans('Anna oikeanmuotoinen www-osoite.', {}, 'common'),
            min: ({ min }) => _trans(`Kentän vähimmäispituus on ${min} merkkiä.`, {}, 'common'),
            max: ({ max }) => _trans(`Kentän enimmäispituus on ${max} merkkiä.`, {}, 'common'),
        },
        date: {
            min: ({ min }) => {
                // Pvm lokalisaationmukaiseen pvm-muotoon. Tutkiin onko string tahi Date-objekti.
                const minDate = typeof (min) === 'string'
                    ? _toLocaleDate(min)
                    : min?.toLocaleDateString(_languageCulture);

                return _trans('Anna päivämäärä joka on %min% tai sen jälkeen.', { min: minDate }, 'common');
            },
        },
        number: {
            min: ({ min }) => _trans(`Arvon on oltava vähintään ${min}.`, {}, 'common'),
            max: ({ max }) => _trans(`Arvon on oltava enintään ${max}.`, {}, 'common'),
            positive: _trans('Anna positiivinen numeerinen arvo.', {}, 'common')
        }
    });
};

/**
 * Stringille positiivisen numeron tarkistus.
 */
Yup.addMethod(Yup.string, 'positive', function(errorMessage = _trans('Anna positiivinen numeerinen arvo. ', {}, 'common')) {
    return this.test(`positive`, errorMessage, function(value) {
        const { path, createError } = this;
        const isPositive = /^(?!-)?\d+( \d+)*([.,]\d+)?$/.test(value);

        return (
            value === undefined ||
            isPositive ||
            createError({ path, message: errorMessage })
        );
    });
});

/**
 * Stringille kokonaislukutarkistus.
 */
Yup.addMethod(Yup.string, 'integer', function(errorMessage = _trans('Anna kokonaisluku. ', {}, 'common')) {
    return this.test(`integer`, errorMessage, function(value) {
        const { path, createError } = this;
        const isInteger = /^-?\d+( \d+)*$/.test(value);

        return (
            value === undefined ||
            isInteger ||
            createError({ path, message: errorMessage })
        );
    });
});

/**
 * Stringille desimaalitarkistus.
 */
Yup.addMethod(Yup.string, 'decimal', function(errorMessage = _trans('Anna numeerinen arvo. ', {}, 'common')) {
    return this.test(`decimal`, errorMessage, function(value) {
        const { path, createError } = this;
        const isDecimal = /^-?\d+( \d+)*([.,]\d+)?$/.test(value);

        return (
            value === undefined ||
            isDecimal ||
            createError({ path, message: errorMessage })
        );
    });
});

/**
 * Stringille pvm tarkistus
 */
Yup.addMethod(Yup.string, 'date', function (errorMessage = _trans('Anna oikeanmuotoinen päivämäärä.', {}, 'common')) {
    return this.test('date', errorMessage, function (value) {
        const { path, createError } = this;

        return (
            value === undefined
            || moment(value, 'DD.MM.YYYY', true).isValid()
            || moment(value, 'D.M.YYYY', true).isValid()
            || moment(value, 'YYYY-MM-DD', true).isValid()
            || moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
            || _.isUndefined(value)
            || createError({ path, message: errorMessage })
        );
    });
});
