import { addLogoutTimer } from 'shared/utils/LogoutDialog';
// Perus requesti. Jos unauthorized niin heitetään logiin reloadin kautta
const request = (url, opts) =>
    fetch(
        url,
        Object.assign({}, opts),
    )
        .then((resp) => {
            addLogoutTimer();
            if (resp.status === 401) {
                // Ei olla logattu sisään, reload niin heittää loginiin
                window.location.reload(true);
                return Promise.reject(resp);
            }
            // Jos muuten tulee yli 400, niin rejectoidaan, että jää kiinni try:n catch:iin
            if (resp.status >= 400) {
                return Promise.reject(resp);
            }

            return resp;
        })
        .then((resp) => resp.json())
        .then((json) => {
            // Heitetään tässä virhettä vain jos valtuushallinna virhe: saattaa hajottaa muita paikkoja muuten
            if (json.status === 'error' && json.error === 'error_no_access_to_dimensions') {
                throw () => json;
            }

            return json;
        })
        .catch((error) => {
            if (typeof error === 'function') {
                return Promise.reject(error());
            } else if (typeof error.json === 'function') {
                return error.json().then((val) => Promise.reject(val));
            } else {
                return error;
            }
        });

// Geneerinen metodi requestin rakenteluun
const makeOptions = (method, body, extraOptions) => (
    Object.assign({}, extraOptions, {
        method,
        // https://developer.mozilla.org/en-US/docs/Web/API/Request/credentials
        credentials: _.get(extraOptions, 'credentials', 'include'),
        body: body && JSON.stringify(body),
        headers: Object.assign({
            'Content-Type': 'application/json',
        }, extraOptions.headers)
    })
);

const get = (url, opts = {}) => request(url, makeOptions('GET', null, opts));
const post = (url, body, opts = {}) => request(url, makeOptions('POST', body, opts));
const put = (url, body, opts = {}) => request(url, makeOptions('PUT', body, opts));
const patch = (url, body, opts = {}) => request(url, makeOptions('PATCH', body, opts));
const del = (url, body, opts = {}) => request(url, makeOptions('DELETE', body, opts));

export default {
    get,
    post,
    put,
    patch,
    del,
};
